import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Layout } from "../components";
import NotificationComponent from "../components/NotificationComponent";
import { fetchSwingUsers } from "../state";

function Notification({
    swingusers, fetchSwingUsers
}) {

    useEffect(() => {
        if (swingusers.length === 0) {
            fetchSwingUsers();
        }
    }, [fetchSwingUsers])

    return (
        <div>
            <Layout>
                <NotificationComponent users={swingusers} />
            </Layout>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        swingusers: state.swing.swingusers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSwingUsers: () => dispatch(fetchSwingUsers()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);