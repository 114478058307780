import React, { useEffect, useState } from "react";
import { NotificationSection, Card, AddForm } from "../style";
import axios from "axios";

const NotificationComponent = ({ users }) => {
    const [state, setState] = useState({})
    const [usersList, setUsersList] = useState([])

    useEffect(() => {
        let tempUsersList = users.filter(user => user.hasOwnProperty("fcmToken"));
        setUsersList(tempUsersList);
    }, [users])

    const handleSubmit = () => {
        console.log("Handle submit notificationsssss")
        if (!state.notificationTitle) {
            return alert("Please enter title");
        }
        if (!state.notificationDesc) {
            return alert("Please enter description");
        }

        usersList.map((user) => {
            //    console.log("fcm token of user", user.fcmToken)
            // if (user.id == 485) {
            console.log("fcm token of user", user.fcmToken)
            let data = {
                //"registration_ids": ["fPrwm0r7Rry2o--JUvO442:APA91bGTQvPewEHdMnApYxXolVvU6ef-Tprgxa0Sa033XO8qbi7jsHj57jF975IWdb-9JS15P2LIlIhvbHmk6lz4orgbh5eCapjaZJ9r1Ml8xNgytnxvGUrQTQsOFlmKyw2rHjTj8u7i"],
                "registration_ids": [user.fcmToken],
                "notification": {
                    "title": state.notificationTitle,
                    "body": state.notificationDesc,
                },
                "data": {}
            };

            console.log("data for notification", user.fcmToken)
            console.log("notification sent", data)

            axios({
                method: 'post',
                url: "https://fcm.googleapis.com/fcm/send",
                data: data,
                headers: {
                    Authorization: "key=AAAAvaEfi3U:APA91bEWnWAfdsNJtI4VBM93DdNVVJIfYzeLoiWR6COQvj9IWv6mjf08jwdwkkHv7Fiqu43LO67SOmsmuWE8VuIJS9zqdr4pE_c28QnKsD7LQ5PZpkGDe1yomsdPBK6QupDClXx5K0-k"
                }
            })
                .then(res => {
                    alert("Notification Sent!!!")
                })
                .catch(err => {
                    alert("Failed to send notification!!!")
                })
            // }
        })
    }

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    return (
        <div>
            <NotificationSection>
                <h1> Notifications </h1>
                <Card>
                    <AddForm
                        onSubmit={handleSubmit}
                    >
                        <label htmlFor="notificationTitle" style={{ color: "#001128", width: "100%" }}>
                            Title
                            <input
                                className="myClass"
                                id="notificationTitle"
                                name="notificationTitle"
                                type="text"
                                placeholder={"Title"}
                                placeholderTextColor={"#001128"}
                                onChange={handleChange}
                            />
                        </label>
                        <br />
                        <label htmlFor="notificationDesc" style={{ color: "#001128", width: "100%" }}>
                            Description
                            <textarea
                                id="notificationDesc"
                                name="notificationDesc"
                                placeholder={"Description"}
                                placeholderTextColor={"#001128"}
                                onChange={handleChange}
                            >
                            </textarea>
                        </label>
                        <br />
                        <button
                            type="submit"
                            aria-disabled={true}
                            style={{
                                borderRadius: "5px",
                                backgroundColor: "#AA0BCA",
                                cursor: "pointer",
                                height: "2.5rem"
                            }}
                        >
                            Send Notification
                        </button>
                    </AddForm>
                </Card>
            </NotificationSection>
        </div>
    )
}

export default NotificationComponent;